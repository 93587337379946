import { default as indext9XSszbi71Meta } from "/root/project/frontend/pages/about-us/index.vue?macro=true";
import { default as indexfsmp7dMf6mMeta } from "/root/project/frontend/pages/apply/index.vue?macro=true";
import { default as institutebuaPJAqjdbMeta } from "/root/project/frontend/pages/apply/institute.vue?macro=true";
import { default as reviewuzr4Glw85GMeta } from "/root/project/frontend/pages/apply/review.vue?macro=true";
import { default as forgot_45passwordq1VxpZTkhfMeta } from "/root/project/frontend/pages/auth/forgot-password.vue?macro=true";
import { default as loginEHGnXXsj1QMeta } from "/root/project/frontend/pages/auth/login.vue?macro=true";
import { default as new_45passwordhB0l3MAfJ5Meta } from "/root/project/frontend/pages/auth/new-password.vue?macro=true";
import { default as signupnleeOK627sMeta } from "/root/project/frontend/pages/auth/signup.vue?macro=true";
import { default as verify8MIp2B9uH3Meta } from "/root/project/frontend/pages/auth/verify.vue?macro=true";
import { default as indexQfGk1a4ThlMeta } from "/root/project/frontend/pages/book-appointment/index.vue?macro=true";
import { default as indexmVIChce8ZQMeta } from "/root/project/frontend/pages/course/[id]/index.vue?macro=true";
import { default as indexTbawHobD6OMeta } from "/root/project/frontend/pages/destinations/index.vue?macro=true";
import { default as indexjpAUGJWQ8LMeta } from "/root/project/frontend/pages/index.vue?macro=true";
import { default as _91id_93PcI60TSsuLMeta } from "/root/project/frontend/pages/institute/[id].vue?macro=true";
import { default as _91id_93tjde5Xvma4Meta } from "/root/project/frontend/pages/institutes/[id].vue?macro=true";
import { default as _91id_930VWs39PLtBMeta } from "/root/project/frontend/pages/institutes/course/[id].vue?macro=true";
import { default as indexD4u9h125SwMeta } from "/root/project/frontend/pages/listApplication/[id]/index.vue?macro=true";
import { default as _91id_930rGO4tcb56Meta } from "/root/project/frontend/pages/listApplication/files/[id].vue?macro=true";
import { default as index0QEsFdNnDzMeta } from "/root/project/frontend/pages/listApplication/index.vue?macro=true";
import { default as indexTixsvvDUN8Meta } from "/root/project/frontend/pages/privacy-and-policy/index.vue?macro=true";
import { default as applicationjEqTzl9jMKMeta } from "/root/project/frontend/pages/profile/application.vue?macro=true";
import { default as compareYlVXGwYpQ9Meta } from "/root/project/frontend/pages/profile/compare.vue?macro=true";
import { default as documentsxyRTVCH6TjMeta } from "/root/project/frontend/pages/profile/documents.vue?macro=true";
import { default as favoritesAC1LcgqweJMeta } from "/root/project/frontend/pages/profile/favorites.vue?macro=true";
import { default as indexm98AyiWbr1Meta } from "/root/project/frontend/pages/profile/index.vue?macro=true";
import { default as notificationskbftiFLkKLMeta } from "/root/project/frontend/pages/profile/notifications.vue?macro=true";
import { default as offerst5jdLIUG5eMeta } from "/root/project/frontend/pages/profile/offers.vue?macro=true";
import { default as profilesD2QDji6aeMeta } from "/root/project/frontend/pages/profile.vue?macro=true";
import { default as indexgkyOh3w27tMeta } from "/root/project/frontend/pages/quickapply/index.vue?macro=true";
import { default as _91category_93_45coursefXH8q9MlXwMeta } from "/root/project/frontend/pages/search/[category]-course.vue?macro=true";
import { default as _91category_93CyBMJO5JOlMeta } from "/root/project/frontend/pages/search/[category].vue?macro=true";
import { default as indexCszXZIyLliMeta } from "/root/project/frontend/pages/subject/[id]/index.vue?macro=true";
import { default as indexzD5l8ewkA2Meta } from "/root/project/frontend/pages/terms-and-conditions/index.vue?macro=true";
import { default as testlOEaX63A5JMeta } from "/root/project/frontend/pages/test.vue?macro=true";
import { default as indexKCWmRnzVU8Meta } from "/root/project/frontend/pages/thank-you/index.vue?macro=true";
import { default as _91id_93ElVkT0thiVMeta } from "/root/project/frontend/pages/university/[id].vue?macro=true";
import { default as _91id_93nuQqZ0uvzqMeta } from "/root/project/frontend/pages/university/course/[id].vue?macro=true";
import { default as indexFe6UZoco05Meta } from "/root/project/frontend/pages/why-acceptix/index.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: indext9XSszbi71Meta || {},
    component: () => import("/root/project/frontend/pages/about-us/index.vue")
  },
  {
    name: "apply",
    path: "/apply",
    component: () => import("/root/project/frontend/pages/apply/index.vue")
  },
  {
    name: "apply-institute",
    path: "/apply/institute",
    component: () => import("/root/project/frontend/pages/apply/institute.vue")
  },
  {
    name: "apply-review",
    path: "/apply/review",
    component: () => import("/root/project/frontend/pages/apply/review.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordq1VxpZTkhfMeta || {},
    component: () => import("/root/project/frontend/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginEHGnXXsj1QMeta || {},
    component: () => import("/root/project/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-new-password",
    path: "/auth/new-password",
    meta: new_45passwordhB0l3MAfJ5Meta || {},
    component: () => import("/root/project/frontend/pages/auth/new-password.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupnleeOK627sMeta || {},
    component: () => import("/root/project/frontend/pages/auth/signup.vue")
  },
  {
    name: "auth-verify",
    path: "/auth/verify",
    meta: verify8MIp2B9uH3Meta || {},
    component: () => import("/root/project/frontend/pages/auth/verify.vue")
  },
  {
    name: "book-appointment",
    path: "/book-appointment",
    component: () => import("/root/project/frontend/pages/book-appointment/index.vue")
  },
  {
    name: "course-id",
    path: "/course/:id()",
    component: () => import("/root/project/frontend/pages/course/[id]/index.vue")
  },
  {
    name: "destinations",
    path: "/destinations",
    meta: indexTbawHobD6OMeta || {},
    component: () => import("/root/project/frontend/pages/destinations/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/project/frontend/pages/index.vue")
  },
  {
    name: "institute-id",
    path: "/institute/:id()",
    meta: _91id_93PcI60TSsuLMeta || {},
    component: () => import("/root/project/frontend/pages/institute/[id].vue")
  },
  {
    name: "institutes-id",
    path: "/institutes/:id()",
    meta: _91id_93tjde5Xvma4Meta || {},
    component: () => import("/root/project/frontend/pages/institutes/[id].vue")
  },
  {
    name: "institutes-course-id",
    path: "/institutes/course/:id()",
    meta: _91id_930VWs39PLtBMeta || {},
    component: () => import("/root/project/frontend/pages/institutes/course/[id].vue")
  },
  {
    name: "listApplication-id",
    path: "/listApplication/:id()",
    component: () => import("/root/project/frontend/pages/listApplication/[id]/index.vue")
  },
  {
    name: "listApplication-files-id",
    path: "/listApplication/files/:id()",
    component: () => import("/root/project/frontend/pages/listApplication/files/[id].vue")
  },
  {
    name: "listApplication",
    path: "/listApplication",
    component: () => import("/root/project/frontend/pages/listApplication/index.vue")
  },
  {
    name: "privacy-and-policy",
    path: "/privacy-and-policy",
    component: () => import("/root/project/frontend/pages/privacy-and-policy/index.vue")
  },
  {
    name: profilesD2QDji6aeMeta?.name,
    path: "/profile",
    component: () => import("/root/project/frontend/pages/profile.vue"),
    children: [
  {
    name: "profile-application",
    path: "application",
    component: () => import("/root/project/frontend/pages/profile/application.vue")
  },
  {
    name: "profile-compare",
    path: "compare",
    component: () => import("/root/project/frontend/pages/profile/compare.vue")
  },
  {
    name: "profile-documents",
    path: "documents",
    component: () => import("/root/project/frontend/pages/profile/documents.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/root/project/frontend/pages/profile/favorites.vue")
  },
  {
    name: "profile",
    path: "",
    component: () => import("/root/project/frontend/pages/profile/index.vue")
  },
  {
    name: "profile-notifications",
    path: "notifications",
    component: () => import("/root/project/frontend/pages/profile/notifications.vue")
  },
  {
    name: "profile-offers",
    path: "offers",
    component: () => import("/root/project/frontend/pages/profile/offers.vue")
  }
]
  },
  {
    name: "quickapply",
    path: "/quickapply",
    component: () => import("/root/project/frontend/pages/quickapply/index.vue")
  },
  {
    name: "search-category-course",
    path: "/search/:category()-course",
    meta: _91category_93_45coursefXH8q9MlXwMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category]-course.vue")
  },
  {
    name: "search-category",
    path: "/search/:category()",
    meta: _91category_93CyBMJO5JOlMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category].vue")
  },
  {
    name: "subject-id",
    path: "/subject/:id()",
    meta: indexCszXZIyLliMeta || {},
    component: () => import("/root/project/frontend/pages/subject/[id]/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/root/project/frontend/pages/terms-and-conditions/index.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/root/project/frontend/pages/test.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/root/project/frontend/pages/thank-you/index.vue")
  },
  {
    name: "university-id",
    path: "/university/:id()",
    meta: _91id_93ElVkT0thiVMeta || {},
    component: () => import("/root/project/frontend/pages/university/[id].vue")
  },
  {
    name: "university-course-id",
    path: "/university/course/:id()",
    meta: _91id_93nuQqZ0uvzqMeta || {},
    component: () => import("/root/project/frontend/pages/university/course/[id].vue")
  },
  {
    name: "why-acceptix",
    path: "/why-acceptix",
    meta: indexFe6UZoco05Meta || {},
    component: () => import("/root/project/frontend/pages/why-acceptix/index.vue")
  }
]