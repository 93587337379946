<template>
  <div @click="closeMenu">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script>
import { useFcm } from '@/composables/useFcm';

const { getFcm } = useFcm();
export default {
  async mounted() {
    this.$primevue.config.ripple = true;
    await this.$store.dispatch('countries/getCountriesRegistration');
    await getFcm();
  },
  methods: {
    closeMenu() {
      if (this.$store.state.functions.profileMenu) {
        this.$store.state.functions.profileMenu = false;
      }
      if (this.$store.state.functions.showNotification) {
        this.$store.state.functions.showNotification = false;
      }
      if (this.$store.state.functions.sort) {
        this.$store.state.functions.sort = false;
      }
    },
  },
};
</script>
