import { useAxios } from '@/composables/useAxios';
import countries from '~/src/countries.json';

export const state = () => ({
  countries: [],
  countriesListQuery: [],
  countriesRegistration: [],
  countriesCodeListRegistration: [],
  filterCountries: [],
  loading: false,
  currentPage: 1,
  lastPage: 1,
  isPaginationFinished: false,
});

export const actions = {
  resetGetCountriesRegistration({ state }) {
    state.currentPage = 1;
    state.lastPage = 1;
    state.isPaginationFinished = false;
    state.countriesRegistration = [];
    state.countriesCodeListRegistration = [];
  },

  async getCountriesRegistration({ state }) {
    if (state.countriesCodeListRegistration.length > 0) return;
    try {
      state.loading = true;
      const { api } = useAxios();
      const response = await api.get(`/countries/registration?page=${state.currentPage}`);

      const resCountries = response?.data?.data ?? [];
      const meta = response?.data?.meta ?? {};

      state.countriesCodeListRegistration = resCountries
        .filter(resItem => Boolean(resItem.iso_code))
        .map(resItem => {
          const country = countries?.data?.find(c => c.iso_code === resItem.iso_code);
          if (!country) return null;

          const show = country.flag || country.emoji || '';
          return {
            id: resItem.id,
            show,
            code: resItem.code ?? '',
            flag: country.flag ?? '',
            name: resItem.name ?? '',
            numberLength: resItem.number_length ?? 0,
          };
        })
        .filter(Boolean); // Remove any null values from the array

      state.currentPage = meta.current_page ?? 1;
      state.lastPage = meta.last_page ?? 1;
      state.isPaginationFinished = state.currentPage === state.lastPage;
    } catch {
      state.countriesCodeListRegistration = [];
    } finally {
      state.loading = false;
    }
  },

  getCountries({ state }) {
    const { api } = useAxios();
    api.get('/countries').then(res => {
      state.countries = res.data.data;
      state.countriesListQuery = res.data.data.map(item => {
        return {
          id: item.id,
          name: item.name,
          query: `&country_ids[]=${item.id}`,
          show: item.image,
        };
      });
    });
  },

  getFilterCountries({ state }, payload) {
    const { api } = useAxios();
    api.get(`/countries?${payload}`).then(res => {
      state.filterCountries = res.data.data.map(item => {
        return {
          id: item.id,
          name: item.name,
          show: item.image,
          flag: item.flag,
        };
      });
    });
  },
};
