import revive_payload_client_4sVQNw7RlN from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/project/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/project/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/root/project/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/root/project/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/project/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_y9dIov5OEk from "/root/project/frontend/node_modules/nuxt3-vuex-module/src/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/root/project/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/root/project/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/root/project/frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/root/project/frontend/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import broadcast_channel_WUg1bHbhSQ from "/root/project/frontend/plugins/broadcast-channel.js";
import firebase_client_zXNDa0wxFH from "/root/project/frontend/plugins/firebase.client.ts";
import globals_W2yFmejOiw from "/root/project/frontend/plugins/globals.js";
import sentry_PZVGm6LH3G from "/root/project/frontend/plugins/sentry.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_y9dIov5OEk,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  broadcast_channel_WUg1bHbhSQ,
  firebase_client_zXNDa0wxFH,
  globals_W2yFmejOiw,
  sentry_PZVGm6LH3G
]